$icon-font-path: "/fonts/"; // correct bootstrap font path
@import "bootstrap";
@import "yeti/variables";
$icon-font-path: "/fonts/"; // re-correct bootstrap font path
@import "bootstrap";
@import "yeti/variables";

@import "yeti/bootswatch";

$fa-font-path: "/fonts/"; // correct font-awesome font path
@import "font-awesome";


@import "dataTables-bootstrap3";
@import "metisMenu";
@import "colorbox";




/* Sticky footer styles
-------------------------------------------------- */
$footer-height: 60px;

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: $footer-height;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: $footer-height;
  background-color: #f5f5f5;
}



/* Dashboard styles
-------------------------------------------------- */

.main .page-header {
  margin-top: 0;
}

li > ul {
  margin-left: 10px !important;
}

/* Colorbox corrections
-------------------------------------------------- */
#cboxLoadingGraphic{background:url(/img/loading.gif) no-repeat center center;}
#cboxPrevious{position:absolute; top:50%; left:5px; margin-top:-32px; background:url(/img/controls.png) no-repeat top left; width:28px; height:65px; text-indent:-9999px;}
#cboxNext{position:absolute; top:50%; right:5px; margin-top:-32px; background:url(/img/controls.png) no-repeat top right; width:28px; height:65px; text-indent:-9999px;}
#cboxClose{position:absolute; top:5px; right:5px; display:block; background:url(/img/controls.png) no-repeat top center; width:38px; height:19px; text-indent:-9999px;}
